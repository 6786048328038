.grid-sparkline-tooltip {
  padding: 5px;
  background-color: rgb(19, 62, 96);
  border: 1px;
  border-radius: 5px;
}

.grid-sparkline-tooltip-title {
  color: white;
  font-size: 12px;
  font-weight: 500;
  font-family: 'Roboto';
}

.header-no-padding {
  padding: 0px;
  justify-content: center;
}

.grid-detail-icon .ag-group-contracted {
  width: 100%;
  justify-content: center;
}

.grid-detail-icon .ag-group-expanded {
  width: 100%;
  justify-content: center;
}

/* .ag-horizontal-left-spacer { remove overflow in pinned add box
  overflow: hidden;
} */

/*leave commented out
/* .ag-theme-alpine-dark .ag-header-cell-label-container {
  padding: 0px;
  width: 75px;
} */
