.rv-xy-plot__axis__title text {
  fill: #f0f0f0;
  font-size: 16px;
  font-weight: 500;
}

.rv-xy-plot__axis__tick__text {
  fill: #f0f0f0;
}

.rv-xy-plot__grid-lines__line {
  stroke: #4d4d4d;
}

/* this is a "fix" to FlexibleXYPlot not resizing properly after client area shrinks, but doing this here
   screws all other plots...
.rv-xy-plot {
  // decouples the outer container width from inner container width (width check happens before svg render) 
  position: absolute; 
  // masks any glitches caused by denounce on resize event (obv combined with a bit lighter denounce would help more) 
  overflow: visible;
}
*/