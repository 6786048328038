html,
body,
#root {
  height: 100vh;
  background: #2d2d2d;
  --scrollbar-width: 16px;
  --scrollbar-bg-color: #192734;
  --scrollbar-track-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  --scrollbar-thumb-bg-color: #133f60;
}

.ag-header-cell-label {
  justify-content: center;
}

.customScrollBarDark {
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    background-color: #192734;
  }

  &::-webkit-scrollbar {
    width: 16px;
    background-color: #192734;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #133f60;
  }
}
